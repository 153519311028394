
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "info-modal",
  components: {},
  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null);
    const infoModalRef = ref<null | HTMLElement>(null);
    
    const info = store.getters.getSupplyBoxInfo;

    const { t, te } = useI18n();
    
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    }

    const rules = ref({
      center: [
        {
          required: true,
          message: translate("centerRequired"),
          trigger: "change",
        },
      ],
      hospital: [
        {
          required: true,
          message: translate("hospitalRequired"),
          trigger: "change",
        },
      ]
    });

    const submit = () => {
      store.dispatch(Actions.UPDATE_INFO, info);
        
      hideModal(infoModalRef.value);
    };

    return {
      info,
      rules,
      submit,
      formRef,
      infoModalRef,
      translate
    };
  },
});
