
import { defineComponent } from "vue";
import ChangeInfoModal from "@/components/modals/forms/ChangeInfoModal.vue";
import store from "@/store";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "kt-details",
  components: {
    ChangeInfoModal,
  },
  setup(){
    const userAuthenticated = store.getters.isUserAuthenticated;

    const info = store.getters.getSupplyBoxInfo;
    
    const { t, te } = useI18n();
    
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    }

    return {
      info,
      userAuthenticated,
      translate
    }
  },
});
