
import { defineComponent, nextTick, onMounted } from "vue";
import Details from "@/components/info/view/Details.vue";
import { StickyComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "kt-view-subscription",
  components: {
    Details
  },
  setup() {
    const { t, te } = useI18n();
    
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    }

    onMounted(() => {
      setCurrentPageBreadcrumbs(translate("info"), []);
      window.addEventListener("locale-changed", (event) => {
        setCurrentPageBreadcrumbs(translate("info"), []);
      });
      nextTick(() => {
        StickyComponent.reInitialization();
      });
    });
  },
 });
